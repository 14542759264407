import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Dialog as MaterialDialog, makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { MultiplyIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton } from "../iconButton";
import classNames from "classnames";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const variantType = {
  dialog: "dialog",
  overlay: "overlay",
};

const propTypes = {
  body: PropTypes.any, // ReactNode | string
  bodyScrollable: PropTypes.bool,
  closeIcon: PropTypes.bool,
  footer: PropTypes.any, // ReactNode | string
  handleClose: PropTypes.func,
  height: PropTypes.string,
  maxDialogWidth: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  rootClassName: PropTypes.string,
  title: PropTypes.any, // ReactNode | string
  variant: PropTypes.string,
  hasFooterScrollShadow: PropTypes.bool,
  closeIconClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

const Dialog = ({
  body,
  bodyScrollable = false,
  closeIcon = false,
  footer,
  handleClose = (_event) => {},
  height,
  maxDialogWidth = "600px",
  open = false,
  onClose = (_event) => {},
  rootClassName = "",
  title,
  variant = "",
  hasFooterScrollShadow = false,
  closeIconClassName = "",
  wrapperClassName = "",
}) => {
  const bodyHeight = footer ? 255 : 145;
  const useStyles = makeStyles(() => ({
    root: {
      zIndex: "1300 !important",
      "& .MuiPaper-root": {
        maxWidth: maxDialogWidth,
      },
      "&.gx-dialog": {
        "& .MuiPaper-root": {
          width: "600px",
        },
        "& .gx-dialog-footer": {
          marginTop: "56px",
        },
      },
    },
    container: {
      height: height,
      padding: 36,
      "& button": {
        borderRadius: 0,
      },
      "&.gx-scrollable": {
        padding: "0px",
        overflowY: "hidden",
        "& .gx-dialog-title": {
          position: "sticky",
          top: "0px",
          padding: "20px",
        },
        "& .gx-dialog-body": {
          paddingLeft: "30px",
          paddingRight: "30px",
          overflowY: "auto",
          overflowX: "hidden",
          height: `calc(100% - ${bodyHeight}px)`,
        },
      },
    },
    title: {
      marginBottom: 10,
      "& h4": {
        color: "var(--color-neutrals-carbon)",
      },
      "& button": {
        position: "absolute",
        right: "32px",
        top: "24px",
      },
    },
    body: {
      marginBottom: 20,
      paddingTop: 6,
      "& p": {
        color: "var(--color-neutrals-coal)",
      },
    },
    footer: {
      marginTop: 36,
      "&.scrolling": {
        boxShadow: " 0px 4px 12px 0px rgba(20, 19, 18, 0.4)",
      },
    },
  }));
  const { t } = useTranslation();
  const [isScrolling, setIsScrolling] = useState(false);

  const handleCloseDialog = (isClosing, e) => {
    if (isClosing) {
      handleEvent(handleClose, e);
      handleEvent(onClose, e);
    }
  };

  const classes = useStyles();
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setIsScrolling(scrollTop !== 0 && scrollTop !== scrollHeight - clientHeight);
  };
  return (
    <MaterialDialog
      open={open}
      onClose={(e) => handleCloseDialog(!closeIcon, e)}
      PaperProps={{ style: { borderRadius: 8, overflowY: "hidden", maxWidth: maxDialogWidth } }}
      BackdropProps={{ style: { backgroundColor: "rgb(103, 101, 101, 0.5)" } }}
      classes={{ root: classes.root }}
      className={classNames(variantType.dialog === variantType[variant] && "gx-dialog", rootClassName)}
    >
      <div className={classNames(classes.container, bodyScrollable && "gx-scrollable", wrapperClassName)}>
        <div className={classNames(classes.title, "gx-dialog-title")}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"h4"}>{title}</TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <h4>{title}</h4>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {closeIcon && (
            <IconButton
              ariaLabel={t("close icon")}
              rootClassName={classNames(closeIconClassName)}
              onClick={(e) => handleCloseDialog(true, e)}
              focusBackgroundColor={colorPalette.basic.white.hex}
              hoverBackgroundColor={colorPalette.basic.white.hex}
            >
              <MultiplyIcon />
            </IconButton>
          )}
        </div>
        <div
          onScroll={(e) => {
            if (hasFooterScrollShadow) {
              handleScroll(e);
            }
          }}
          className={classNames(classes.body, "gx-dialog-body")}
        >
          {body}
        </div>
        <div className={classNames(classes.footer, "gx-dialog-footer", isScrolling && "scrolling")}>{footer}</div>
      </div>
    </MaterialDialog>
  );
};
Dialog.propTypes = propTypes;
export { Dialog, variantType };
