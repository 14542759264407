import { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Avatar, makeStyles } from "@material-ui/core";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { PendingPersonIcon } from "gx-npm-icons";
import { defaultToEmptyString, getAcronym } from "gx-npm-lib";
import { genUniqueNumber, getUserIdColorClass } from "./lib";
import { userDisplayStyles as styles } from "./styles";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const generateClassName = createGenerateClassName({
  seed: "UsernameDisplay-UI",
});

const propTypes = {
  ariaLabel: PropTypes.string,
  colorMod: PropTypes.number,
  email: PropTypes.string,
  iconTitle: PropTypes.string,
  id: PropTypes.number,
  invertColor: PropTypes.bool,
  isColorInvertedOnHover: PropTypes.bool,
  isOwner: PropTypes.bool,
  isCreatedBy: PropTypes.bool,
  isUnknown: PropTypes.bool,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  showEmail: PropTypes.bool,
  showEmptyAvatar: PropTypes.bool,
  showName: PropTypes.bool,
  showOnlyFirstName: PropTypes.bool,
  roleDisplayFlexDirection: PropTypes.oneOf(["column", "row"]),
  isGreyScaled: PropTypes.bool,
  isSmallerIcon: PropTypes.bool,
};
/*
ariaLabel aria-label on parent container
colorMod number value to set avatar color to (-1 - 8 valid values, -1 will not be used)
email ::  email address of user
iconTitle
invertColor flag to invert colors
isColorInvertedOnHover inverts the color of avatar when hovered
isOwner flag for displaying "(Owner)" after name
isCreatedBy flag for displaying "(Created by)" after name
isUnknown
name full name of user
rootClassName additional class name set at the root for styling overriding
showEmail flag for showing email address
showEmptyAvatar flag showing avatar even when no name available
showName flag for showing user name
showOnlyFirstName flag for showing only first name of user name
roleDisplayFlexDirection align the role of user in row or column with respect to flex layout
isGreyScaled :: If true will use grey scale colours
isSmallerIcon :: for icon of size 24 * 24, default is 48 * 48
 */
const UsernameDisplay = ({
  ariaLabel = "",
  colorMod = -1,
  email = "",
  iconTitle = "",
  invertColor = false,
  isColorInvertedOnHover = false,
  isOwner = false,
  isCreatedBy = false,
  isUnknown = false,
  name = "",
  rootClassName = "",
  showEmail = false,
  showEmptyAvatar = false,
  showName = true,
  showOnlyFirstName = false,
  roleDisplayFlexDirection = "row",
  isGreyScaled = false,
  isSmallerIcon = false,
}) => {
  const [displayName, setDisplayName] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [userColorClass, setUserColorClass] = useState("");

  useEffect(() => {
    let value = "";
    if (!isUnknown) {
      const id = genUniqueNumber(name);
      value = isGreyScaled ? "grey" : getUserIdColorClass(id, colorMod);
    }
    setUserColorClass(value);
  }, [colorMod, isGreyScaled, isUnknown, name]);

  useEffect(() => {
    const display = defaultToEmptyString(name);
    if (showOnlyFirstName) {
      setDisplayName(display.split(/\s/)[0]);
    } else {
      setDisplayName(display);
    }
  }, [name, showOnlyFirstName]);

  const acronym = getAcronym(name);
  const ownerDisplay = isOwner ? "(Owner)" : "";
  const createdByDisplay = isCreatedBy ? "(Created by)" : "";
  const useStyles = makeStyles(() => styles);
  const classes = useStyles();
  const role = createdByDisplay.length === 0 ? ownerDisplay : createdByDisplay;

  return (
    <StylesProvider generateClassName={generateClassName}>
      <div
        {...(ariaLabel && { "aria-label": ariaLabel })}
        className={classNames(
          "gx-user",
          classes.userWrapper,
          rootClassName,
          userColorClass,
          isUnknown && classes.unknownWrapper,
          invertColor && "invert",
          isHovered && isColorInvertedOnHover && "hovered",
          isSmallerIcon && classes.xs
        )}
      >
        {(acronym || showEmptyAvatar || isUnknown) && (
          <Avatar onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {isUnknown ? <PendingPersonIcon title={iconTitle} showSmallerIcon={isSmallerIcon} /> : acronym}
          </Avatar>
        )}
        {!!showEmail && (
          <div className="gx-userInfo">
            {!!displayName && !!showName && (
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent
                    rootClassName={"gx-displayName-GCOM-3606"}
                    color={isUnknown ? "iron" : "coal"}
                    boldness={"medium"}
                    styling={"p3"}
                  >
                    {displayName}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p className="gx-displayName medium-bold p3">{displayName}</p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            )}
            {!!email && (
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent rootClassName={"gx-email-GCOM-3606"} color={"coal"} styling={"p4"}>
                    {email}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p className="gx-email p4">{email}</p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            )}
          </div>
        )}
        {!showEmail && !!displayName && !!showName && (
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                rootClassName="gx-ava-owner"
                boldness={"medium"}
                style={{ flexDirection: roleDisplayFlexDirection }}
                color={"coal"}
              >
                {roleDisplayFlexDirection === "row" ? (
                  `${displayName} ${role}`
                ) : (
                  <>
                    <TypographyComponent element={"span"} color={"coal"} boldness={"medium"}>
                      {displayName}
                    </TypographyComponent>
                    <TypographyComponent
                      rootClassName={"gx-ava-role"}
                      boldness={"medium"}
                      element={"span"}
                      color={"iron"}
                    >
                      {role}
                    </TypographyComponent>
                  </>
                )}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <span className="gx-ava-owner" style={{ flexDirection: roleDisplayFlexDirection }}>
                {roleDisplayFlexDirection === "row" ? (
                  `${displayName} ${role}`
                ) : (
                  <>
                    <span>{displayName}</span>
                    <span className="gx-ava-role">{role}</span>
                  </>
                )}
              </span>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        )}
      </div>
    </StylesProvider>
  );
};

UsernameDisplay.propTypes = propTypes;
export { UsernameDisplay };
