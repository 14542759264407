import { Fragment, KeyboardEvent, useContext, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isValidDate, UUID } from "gx-npm-lib";
import { FileTypePdfIcon, FileTypeXlsxIcon } from "gx-npm-icons";
import { IconCheckMarkComplete } from "../../ui/icons/document.icons";
import DocumentButtonDelete from "./components/button-delete/document-card-button-delete.component";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { downloadSurveyDocument } from "../../lib/requests.lib";
import { SurveyDocumentsAppContext } from "../../app.context";
import { UserViews } from "../../app.types";
import styles from "./document-card.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

type DocumentCardUploadedProps = {
  id: UUID;
  isSkipped: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
};

const DocumentCardUploaded = ({
  id,
  isSkipped,
  requestedDocName,
  uploadedDocBy,
  uploadedDocDate,
  uploadedDocName,
}: DocumentCardUploadedProps) => {
  const { t } = useTranslation();
  const [uploadedDocHover, setUploadedDocHover] = useState(false);
  const { initId, initProdId, setShowErrorSnackBar, surveyId, userView } = useContext(SurveyDocumentsAppContext);

  const handleClickDownload = () => {
    downloadSurveyDocument(
      { docId: id, initId, initProdId, surveyId, userView },
      (data) => {
        window.open(data.signedUrl, "_self");
      },
      () => {
        setShowErrorSnackBar(true);
      }
    );
  };

  const handleKeyDownDownload = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClickDownload();
    }
  };

  const renderUploadedDocTypeIcon = () => {
    const fileExtension = uploadedDocName.split(".").pop()?.toLowerCase();

    switch (fileExtension) {
      case "pdf":
        return (
          <span aria-label={t("pdf document")}>
            <FileTypePdfIcon />
          </span>
        );
      case "xls":
      case "xlsx":
        return (
          <span aria-label={t("excel document")}>
            <FileTypeXlsxIcon />
          </span>
        );
    }
  };

  const formatDate = (date: string) => {
    let formattedDate = "";
    if (isValidDate(date)) {
      formattedDate = new Date(date)
        .toLocaleString("en-us", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace(" at ", ", ");
    }
    return formattedDate;
  };

  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness="semi" rootClassName={classNames(styles.requestedDocName)} styling="p2">
            {requestedDocName}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph boldness="bold" rootClassName={classNames(styles.requestedDocName)} type="p2">
            {requestedDocName}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div
        aria-label={t("download document")}
        className={classNames(styles.docCardInner, styles.uploadedDoc)}
        onClick={handleClickDownload}
        onKeyDown={handleKeyDownDownload}
        onFocus={() => setUploadedDocHover(true)}
        onMouseOver={() => setUploadedDocHover(true)}
        onMouseLeave={() => setUploadedDocHover(false)}
        role="button"
        tabIndex={0}
      >
        {userView === UserViews.RECIPIENT && (
          <div className={classNames(styles.uploadedDocComplete)}>
            <IconCheckMarkComplete />
          </div>
        )}
        <div className={classNames(styles.uploadedDocWrapper)}>
          <div className={classNames(styles.uploadedDocType)}>{renderUploadedDocTypeIcon()}</div>
          <div>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent boldness="medium" element={"p"} rootClassName={classNames(styles.uploadedDocName)}>
                  {uploadedDocName}
                </TypographyComponent>
                <TypographyComponent rootClassName={classNames(styles.uploadedDocDate)} styling="p4">
                  {formatDate(uploadedDocDate)}
                </TypographyComponent>
                <TypographyComponent rootClassName={classNames(styles.uploadedDocBy)} styling="p4">
                  {t("by")}
                  {` `}
                  {uploadedDocBy}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <Paragraph boldness="semi" rootClassName={classNames(styles.uploadedDocName)}>
                  {uploadedDocName}
                </Paragraph>
                <Paragraph rootClassName={classNames(styles.uploadedDocDate)} type="p4">
                  {formatDate(uploadedDocDate)}
                </Paragraph>
                <Paragraph rootClassName={classNames(styles.uploadedDocBy)} type="p4">
                  {t("by")}
                  {` `}
                  {uploadedDocBy}
                </Paragraph>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
          {uploadedDocHover && userView === UserViews.RECIPIENT && <DocumentButtonDelete id={id} />}
        </div>
      </div>
      {userView === UserViews.RECIPIENT && <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />}
    </Fragment>
  );
};

export default DocumentCardUploaded;
