import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { SurveyDocumentsAppContextProvider } from "./app.context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import AppPreview from "./app-preview.component";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <SurveyDocumentsAppContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/preview/documents"}
              element={<AppPreview isSetupMode={false} />}
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/preview/documents"}
              element={<AppPreview isSetupMode={true} />}
            />
            <Route
              path={"/s/evaluation/:initiativeId/questionnaire/setup/:surveyId/preview/documents"}
              element={<AppPreview isSetupMode={true} />}
            />
            <Route path={"/s/evaluation/:initiativeId/product/:initProductId/documents"} element={<App />} />
            <Route path={"/s/questionnaire/:initiativeId/product/:initProductId/documents"} element={<App />} />
          </Routes>
        </BrowserRouter>
      </SurveyDocumentsAppContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
