/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Proptypes from "prop-types";
import classNames from "classnames";
import { Dialog } from "@material-ui/core";
import { BuySmartLogo, HomeIcon, RefreshIcon } from "gx-npm-icons";
import { createGenerateClassName, makeStyles, StylesProvider } from "@material-ui/core/styles";
import { defaultToEmptyString } from "gx-npm-lib";
import { errorOverlayStyles as styles } from "./styles";
import labels from "./labels.json";
import { MiniButton } from "../miniButton";
import FlightSvg from "./flightSvg";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const generateClassName = createGenerateClassName({
  seed: "ErrorOverlayV2-UI",
});
const propTypes = { statusCode: Proptypes.number };
const useStyles = makeStyles(() => styles);

/**
 * @param {{ statusCode: number }} props
 * @var props.statusCode - to display appropriate message
 * @returns overlay for entire screen error page
 */
const ErrorOverlayV2 = ({ statusCode = 0 }) => {
  const classes = useStyles();
  let errorMessage = labels.serverRelatedMessage;
  let errorCodeLabel = labels.serverErrorCode;
  let hasRefresh = true;
  if (statusCode >= 400 && statusCode < 500 && statusCode !== 408 && statusCode !== 422) {
    errorMessage = labels.clientRelatedMessage;
    errorCodeLabel = labels.clientErrorCode;
    hasRefresh = false;
  }
  const handleClickRefresh = () => {
    if (window?.location) {
      window.location.href = defaultToEmptyString(window.location.href);
    }
  };
  const handleBackToInitiatives = () => {
    if (window?.location) {
      window.location.href = window.location.origin + "/s/evaluations";
    }
  };
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Dialog classes={{ root: classes.root }} fullScreen={true} open={true}>
        <div className={classes.header}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/control-has-associated-label */}
          <div className={classes.logoWrapper} onClick={handleBackToInitiatives} role="button" tabIndex={0}>
            <BuySmartLogo height="28" variant="blue" width="142" title="" />
          </div>
        </div>
        <div className={classes.container}>
          <div>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent rootClassName={classNames(classes.errorCode)} styling={"p4"}>
                  {errorCodeLabel}
                </TypographyComponent>
                <TypographyComponent rootClassName={classes.title} styling={"h1"}>
                  {labels.title}
                </TypographyComponent>
                <TypographyComponent rootClassName={classNames(classes.message)} styling={"p1"}>
                  {errorMessage}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className={classNames("p4", classes.errorCode)}>{errorCodeLabel}</p>
                <h1 className={classes.title}>{labels.title}</h1>
                <p className={classNames("p1", classes.message)}>{errorMessage}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
            <div className={classes.ctaWrapper}>
              <MiniButton onClick={handleBackToInitiatives}>
                <HomeIcon />
                {labels.backToInitiatives}
              </MiniButton>
              {hasRefresh && (
                <>
                  <div className={classes.separator} />
                  <div className={classes.refreshLink}>
                    <MiniButton onClick={handleClickRefresh}>
                      <RefreshIcon />
                      {labels.refresh}
                    </MiniButton>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.imageWrapper}>
            <FlightSvg />
          </div>
        </div>
      </Dialog>
    </StylesProvider>
  );
};

ErrorOverlayV2.propTypes = propTypes;
export { ErrorOverlayV2 };
