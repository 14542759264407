import { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import NoDocumentsImage from "../../assets/images/no-documents.svg";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import styles from "./no-documents.styles.module.scss";
import InfoOutlineIcon from "./info-outline.icon";
import VectorIcon from "./vector.icon";
import { UserViews, UserViewTypes } from "../../app.types";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

type NoDocumentsSectionProps = {
  userView?: UserViewTypes;
};

const NoDocumentsSection = ({ userView }: NoDocumentsSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.noDocumentsContainer)}>
      <img alt="no documents" className={classNames(styles.noDocumentsImage)} src={NoDocumentsImage} />
      {userView === UserViews.RECIPIENT && (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent color={"iron"} styling="h4">
              {t("You have no document requests")}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Header rootClassName={classNames(styles.noDocumentsText)} type="h4">
              {t("You have no document requests")}
            </Header>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      )}
      {userView === UserViews.EVALUATOR && (
        <Fragment>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"iron"} styling="h4">
                {t("No vendor documents provided.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Header rootClassName={classNames(styles.noDocumentsText)} type="h4">
                {t("No vendor documents provided.")}
              </Header>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          <div className={classNames(styles.noDocumentsIconContainer)}>
            <VectorIcon />
            <div className={classNames(styles.noDocumentsCenteredIcon)}>
              <InfoOutlineIcon />
            </div>
            <VectorIcon />
          </div>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent color={"coal"} rootClassName={classNames(styles.noDocumentsSubtext)} styling="p4">
                {t("Vendor responses in BuySmart are not reviewed by Gartner Experts")}
                {` `}
                {t("and do not necessarily represent the views or opinions of Gartner.")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph rootClassName={classNames(styles.noDocumentsSubtext)} type="p4">
                {t("Vendor responses in BuySmart are not reviewed by Gartner Experts")}
                {` `}
                {t("and do not necessarily represent the views or opinions of Gartner.")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </Fragment>
      )}
    </div>
  );
};

export default NoDocumentsSection;
