import classNames from "classnames";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { miniButtonStyles as styles } from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";

const variantMiniButtonType = {
  darkTheme: "darkTheme",
  destructive: "destructive",
  default: "",
  darkThemeDestructive: "darkThemeDestructive",
};

const propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isSvgAffected: PropTypes.bool,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  variant: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const MiniButton = ({
  ariaLabel = "",
  children,
  disabled = false,
  isSvgAffected = true,
  onClick = () => {},
  rootClassName = "",
  variant = "",
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();

  return (
    <MuiButton
      aria-label={ariaLabel || null}
      className={classNames(
        rootClassName,
        "mini-btn",
        classes.root,
        isFFGCOM3695 && classes.rootGCOM3695,
        variant === variantMiniButtonType.darkTheme && "dark-theme-mini-btn",
        variant === variantMiniButtonType.destructive && "destructive-mini-btn",
        variant === variantMiniButtonType.darkThemeDestructive && "dark-destructive-mini-btn",
        "mini-btn-rounded",
        isSvgAffected && classes.svgEffect
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
};

MiniButton.propTypes = propTypes;
export { MiniButton, variantMiniButtonType };
