import { FC, Fragment } from "react";
import classNames from "classnames";
import { CircularProgress as MuiCircularProgress, makeStyles } from "@material-ui/core";
import { loaderStyles as styles } from "./styles";
import { LoaderProps } from "./loader.types";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";

const useStyles = makeStyles(() => styles);
const Loader: FC<LoaderProps> = ({
  btnClass = "",
  rootClassName = "",
  size = 24,
  source = "",
  variant = "indeterminate",
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  return (
    <Fragment>
      <MuiCircularProgress
        className={classNames(
          btnClass,
          rootClassName,
          classes.top,
          source === "button" && classes.btnLoader,
          isFFGCOM3695 && classes.topGCOM3695
        )}
        size={size}
        variant={variant}
      />
    </Fragment>
  );
};

export default Loader;
