import { colorPalette, weightPalette } from "gx-npm-common-styles";

const blueBerryHex = colorPalette.interactions.blueBerry.hex;
const ironHex = colorPalette.neutrals.iron.hex;
const lightBerryHex = colorPalette.interactions.lightBerry.hex;
const stoneHex = colorPalette.neutrals.stone.hex;
const pearlRgb = colorPalette.neutrals.pearl.rgb;
const quartzHex = colorPalette.neutrals.quartz.hex;
const whiteHex = colorPalette.basic.white.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;
const radioButtonStyles = {
  radio: {
    color: ironHex,
    "&.Mui-checked": {
      color: blueBerryHex,
      "&:hover": {
        backgroundColor: lightBerryHex,
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: lightBerryHex,
      color: blueBerryHex,
    },
    "&.Mui-disabled": {
      color: stoneHex,
    },
    "&:hover": {
      backgroundColor: `rgba(${colorPalette.interactions.lightBerry.rgb}, 0.5 )`,
      color: blueBerryHex,
    },
    "&.dark-theme-radio": {
      color: quartzHex,
      "&.Mui-checked": {
        color: whiteHex,
        "&.Mui-disabled": {
          color: stoneHex,
        },
        "&:hover": {
          backgroundColor: `rgba(${pearlRgb}, 0.6 )`,
          color: quartzHex,
        },
      },
      "&:hover": {
        backgroundColor: `rgba(${pearlRgb}, 0.3 )`,
        color: quartzHex,
      },
      "&.Mui-disabled": {
        color: stoneHex,
      },
      "&.Mui-focusVisible": {
        backgroundColor: `rgba(${pearlRgb}, 0.6 )`,
      },
    },
  },
  label: {
    color: colorPalette.neutrals.carbon.hex,
    fontSize: "14px",
    fontVariationSettings: weightPalette.regular.variation,
    fontWeight: weightPalette.regular.amount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginRight: 0,
    "&.Mui-disabled": {
      color: ironHex,
    },
    "&.dark-theme-radio": {
      color: whiteHex,
      "&.Mui-disabled": {
        color: stoneHex,
      },
    },
  },
  radioGCOM3695: {
    color: ironHex,
    "&.Mui-checked": {
      color: defaultCta,
      "&:hover": {
        backgroundColor: lightBerryHex,
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: lightBerryHex,
      color: selectedCta,
    },
    "&.Mui-disabled": {
      color: stoneHex,
    },
    "&:focus, &:active": {
      backgroundColor: `rgba(${colorPalette.interactions.lightBerry.rgb}, 0.5 )`,
      color: selectedCta,
    },
    "&:hover": {
      backgroundColor: `rgba(${colorPalette.interactions.lightBerry.rgb}, 0.5 )`,
      color: hoverCta,
    },
  },
};

export default radioButtonStyles;
