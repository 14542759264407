import classNames from "classnames";
import { Button, Paragraph } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../ui/dialog/dialog.component";
import styles from "./document-cards-dialog.styles.module.scss";
import { MouseEvent } from "react";

const DialogDeleteDocument = ({
  isOpen = false,
  onClose = (_deleteDocument: boolean) => {
    /* do nothing */
  },
}: {
  isOpen: boolean;
  onClose?: (_deleteDocument: boolean) => void;
}) => {
  const { t } = useTranslation();

  const handleClose = (e: MouseEvent<HTMLButtonElement>, close: boolean) => {
    e.stopPropagation();
    onClose(close);
  };

  return (
    <Dialog
      body={<Paragraph type="p2">{t("Are you sure you want to remove this document?")}</Paragraph>}
      open={isOpen}
      onClose={(e) => handleClose(e, false)}
      title={t("Delete uploaded file?")}
      footer={
        <div className={classNames(styles.btnContainer)}>
          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => handleClose(e, false)}
            rootClassName={classNames("gx-btn-cancel-delete-document", "btn-tertiary")}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={(e: MouseEvent<HTMLButtonElement>) => handleClose(e, true)}
            rootClassName={classNames("gx-btn-delete-document", "primary-destructive-btn")}
          >
            {t("Delete uploaded file")}
          </Button>
        </div>
      }
    />
  );
};

export default DialogDeleteDocument;
