import { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { UserViews, UserViewTypes } from "../../app.types";
import styles from "./document-card.styles.module.scss";
import { UUID } from "gx-npm-lib";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

type DocumentCardSkipProps = {
  id: UUID;
  isSkipped: boolean;
  requestedDocName: string;
  userView?: UserViewTypes;
};

const DocumentCardSkip = ({ id, isSkipped, requestedDocName, userView }: DocumentCardSkipProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent boldness="semi" rootClassName={classNames(styles.requestedDocName)} styling="p2">
            {requestedDocName}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Paragraph boldness="bold" rootClassName={classNames(styles.requestedDocName)} type="p2">
            {requestedDocName}
          </Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      <div className={classNames(styles.skippedDivider)} />
      <div className={classNames(styles.docCardInner, styles.skipped)}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent boldness="medium" rootClassName={classNames(styles.skippedDocName)} styling="p4">
              {t("This document has been skipped.")}
            </TypographyComponent>
            {userView === UserViews.RECIPIENT && (
              <TypographyComponent rootClassName={classNames(styles.uploadedDocDesc)} styling="p4">
                {t("To edit your response and upload a document, uncheck the box below.")}
              </TypographyComponent>
            )}
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph boldness="semi" rootClassName={classNames(styles.skippedDocName)} type="p4">
              {t("This document has been skipped.")}
            </Paragraph>
            {userView === UserViews.RECIPIENT && (
              <Paragraph rootClassName={classNames(styles.uploadedDocDesc)} type="p4">
                {t("To edit your response and upload a document, uncheck the box below.")}
              </Paragraph>
            )}
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      {userView === UserViews.RECIPIENT && <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />}
    </Fragment>
  );
};

export default DocumentCardSkip;
