import { colorPalette } from "gx-npm-common-styles";

const numberFieldStyles = {
  container: {
    "&.focused, &.focused.hovered": {
      "& fieldset": {
        border: `2px solid ${colorPalette.interactions.blueBerry.hex}`,
        marginBottom: 2,
        marginTop: 2,
      },
    },
    "&.hovered": {
      "& fieldset": {
        border: `1px solid ${colorPalette.neutrals.stone.hex}`,
        marginBottom: 2,
        marginTop: 2,
      },
    },
    "& .gx-number-input": {
      width: "112px",
      "& .Mui-focused, & .MuiInput-root:before": {
        border: "none",
      },
      "& input": {
        textAlign: "right",
      },
      "& fieldset": {
        border: 0,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    "& .gx-end-adornment": {
      pointerEvents: "none",
      "& p": {
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
    "& .gx-start-adornment": {
      pointerEvents: "none",
    },
    "& .gx-number-input input, & .gx-end-adornment p": {
      fontSize: "18px",
      fontVariationSettings: "'wght' 600",
      fontWeight: "600",
      letterSpacing: "0.25px",
      lineHeight: "140%",
      color: colorPalette.neutrals.carbon.hex,
    },
    "&.disabled": {
      "& input.Mui-disabled, & .Mui-disabled": {
        color: colorPalette.neutrals.carbon.hex,
        textFillColor: colorPalette.neutrals.carbon.hex,
      },
      "& div:first-of-type.Mui-disabled fieldset": {
        border: 0,
      },
    },
  },
  containerGCOM3695: {
    "&.focused, &.focused.hovered": {
      "& fieldset": {
        border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
        marginBottom: 2,
        marginTop: 2,
      },
    },
  },
};

export { numberFieldStyles };
