import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
  useFeatureFlag,
} from "../featureFlags";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FormControl, makeStyles, TextField as MUITextField, FormHelperText } from "@material-ui/core";
import { textStyles } from "./styles.js";
import { GCOM_3695_ctaButtonColorUpdate, GCOM_3577_GCOM_3677__updateTextField } from "../feature-flags";

const propTypes = {
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isError: PropTypes.bool,
  maxAllowedChars: PropTypes.number,
  multiline: PropTypes.bool,
  multilinev2: PropTypes.bool,
  rootClassName: PropTypes.string,
  showCharCount: PropTypes.bool,
  showLabel: PropTypes.bool,
  showOverflow: PropTypes.bool,
  showMaxCharLabel: PropTypes.bool,
  showVerticalResize: PropTypes.bool,
  value: PropTypes.string,
  // props potentially in ...others
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  InputProps: PropTypes.any,
  type: PropTypes.string,
  pattern: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  inputProps: PropTypes.object,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
/*
helperText - text to display below the textField
isError - displays error styling if true
maxAllowedChars - maximum character limit
multiline - displays multiline
required - displays asterisk if true
rootClassName - custom user class name
showLabel - show label when value is present
showCharCount - show character count
showMaxCharLabel - show label for max character count
showVerticalResize - show vertical resize
value - value to pass in
 */

const useStyles = makeStyles(() => textStyles);

const TextField = (props) => {
  const {
    helperText = "",
    isError = false,
    maxAllowedChars = 0,
    multiline = false,
    multilinev2 = false,
    rootClassName = "",
    showCharCount = false,
    showOverflow = false,
    showLabel = true,
    showMaxCharLabel = false,
    showVerticalResize = false,
    ...others
  } = props;

  const { t } = useTranslation();
  const isGCOM367FFOn = useFeatureFlag(GCOM_3577_GCOM_3677__updateTextField);

  //Todo GCOM-2461: Fix CSS conflict with existing textfield with multiline on & then remove multilinev2 prop
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  let value = props.value ? props.value.replace(/(\r\n|\n|\r)/gm, "") : "";

  const [multipleLines, setMultipleLines] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const formControlRef = useRef();

  if (!isGCOM367FFOn || !showOverflow) {
    if (maxAllowedChars && value.length > maxAllowedChars) {
      value = value.substring(0, maxAllowedChars);
    }
  }

  const classes = useStyles();

  useEffect(() => {
    setCharCount(value.length);

    if (multilinev2 && formControlRef && formControlRef.current) {
      const textAreaHeight = formControlRef.current.querySelector("textarea").scrollHeight;
      const textAreaSingleLineHeight = 48;
      setMultipleLines(textAreaHeight > textAreaSingleLineHeight);
    }
  }, [multilinev2, value]);

  useEffect(() => {
    if (isGCOM367FFOn) {
      if (showCharCount && maxAllowedChars && value.length > maxAllowedChars) {
        setHasError(true);
      } else {
        setHasError(isError);
      }
    }
  }, [isError, maxAllowedChars, value, showCharCount, isGCOM367FFOn]);

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_3577_GCOM_3677__updateTextField}>
      <FeatureFlagBooleanOn>
        <FormControl
          aria-label="textbox"
          className={classnames(
            "gx-text-field-form-control",
            classes.formControlGCOM3677,
            rootClassName,
            "gx-text-field-rounded"
          )}
          ref={formControlRef}
        >
          <MUITextField
            {...others}
            className={classnames(
              "gx-text-field-container",
              classes.text,
              isFFGCOM3695 && classes.textGCOM3695,
              classes.resetBG,
              multilinev2 && "multilinev2",
              multilinev2 && showVerticalResize && "resize-vertical",
              multipleLines && "multiple-lines",
              !showLabel && "hide-label",
              value && "filled",
              hasError && "error",
              "gx-text-field-container-rounded"
            )}
            multiline={multiline || multilinev2}
            variant="outlined"
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk,
              },
            }}
          />
          <div className={classes.helperWrapper}>
            <div>
              {hasError && !isError && (
                <FormHelperText className={classes.error}>{t("You have reached the character limit")}</FormHelperText>
              )}
              {isError && <FormHelperText className={classes.error}>{helperText}</FormHelperText>}
            </div>
            <div>
              {showCharCount && (
                <FormHelperText className={classnames(classes.charCountHelperText, hasError && classes.error)}>
                  {charCount} / {maxAllowedChars}
                </FormHelperText>
              )}
            </div>
          </div>
        </FormControl>
      </FeatureFlagBooleanOn>
      <FeatureFlagBooleanOff>
        <FormControl
          aria-label="textbox"
          className={classnames(
            "gx-text-field-form-control",
            classes.formControl,
            rootClassName,
            "gx-text-field-rounded"
          )}
          ref={formControlRef}
        >
          <MUITextField
            {...others}
            helperText={helperText}
            className={classnames(
              "gx-text-field-container",
              classes.text,
              isFFGCOM3695 && classes.textGCOM3695,
              multilinev2 && "multilinev2",
              multilinev2 && showVerticalResize && "resize-vertical",
              multipleLines && "multiple-lines",
              showCharCount && maxAllowedChars > 0 && "show-char-count",
              !showLabel && "hide-label",
              value && "filled",
              isError && "error",
              "gx-text-field-container-rounded"
            )}
            multiline={multiline || multilinev2}
            variant="outlined"
            {...(maxAllowedChars && { inputProps: { maxLength: maxAllowedChars } })}
            {...(showCharCount &&
              maxAllowedChars > 0 && {
                helperText: (
                  <>
                    {multilinev2 && showMaxCharLabel && charCount >= maxAllowedChars && multipleLines && (
                      <span className={"gx-max-allowed-chars-label"}>You have reached the character limit</span>
                    )}
                    {charCount} / {maxAllowedChars}
                  </>
                ),
                FormHelperTextProps: {
                  classes: {
                    root: classes.charCount,
                  },
                },
              })}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk,
              },
            }}
          />
        </FormControl>
      </FeatureFlagBooleanOff>
    </FeatureFlagBooleanContainer>
  );
};

TextField.propTypes = propTypes;
export default TextField;
