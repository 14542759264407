import { Fragment, ReactNode } from "react";
import classNames from "classnames";
import { IconButton as MaterialIconButton, IconButtonProps as MuiIconButtonProps } from "@material-ui/core";
import styles from "./icon-button.styles.module.scss";

type BackgroundColorTypes = "transparent";
type CursorTypes = "default" | "pointer";
type FillColorTypes = "default" | "stone" | "white";
type FocusBackgroundColorTypes = "apricot" | "transparent" | "white";
type HoverBackgroundColorTypes = "apricot" | "lightberry" | "transparent" | "white";
type HoverFillTypes = "default" | "poison-cherry";
type RippleBackgroundColorTypes = "mild-berry" | "tangerine";

interface IconButtonProps extends MuiIconButtonProps {
  backgroundColor?: BackgroundColorTypes;
  borderRadius?: string | number;
  children?: Array<ReactNode> | ReactNode | number | string;
  className?: string;
  clickable?: boolean;
  cursor?: CursorTypes;
  fillColor?: FillColorTypes;
  focusBackgroundColor?: FocusBackgroundColorTypes;
  hoverBackgroundColor?: HoverBackgroundColorTypes;
  hoverFill?: HoverFillTypes;
  onClick?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (_e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onMouseUp?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  rippleBackgroundColor?: RippleBackgroundColorTypes;
  transparentBackground?: boolean;
}

const IconButton = ({
  backgroundColor = "transparent",
  borderRadius = 0,
  children,
  className,
  clickable = true,
  cursor = "pointer",
  fillColor = "default",
  focusBackgroundColor = "apricot",
  hoverBackgroundColor = "apricot",
  hoverFill = "default",
  onClick = (_e) => {},
  onKeyDown = (_e) => {},
  onMouseUp = (_e) => {},
  rippleBackgroundColor = "tangerine",
  style,
  transparentBackground = false,
  ...props
}: IconButtonProps) => {
  const isNotClickable = transparentBackground || !clickable;

  return (
    <Fragment>
      <MaterialIconButton
        {...props}
        className={classNames(
          "gx-icon-button",
          className,
          isNotClickable && styles.notClickable,
          styles[`background-color-${backgroundColor}`],
          styles[`cursor-${cursor}`],
          styles[`fill-color-${fillColor}`],
          styles[`focus-background-color-${focusBackgroundColor}`],
          styles[`hover-background-color-${hoverBackgroundColor}`],
          styles[`hover-fill-${hoverFill}`],
          styles[`ripple-${rippleBackgroundColor}`],
          styles.icon
        )}
        disableRipple={!clickable}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseUp={onMouseUp}
        style={{ ...style, padding: 0, borderRadius }}
      >
        {children}
      </MaterialIconButton>
    </Fragment>
  );
};

export { IconButton };
