import React, { ReactNode } from "react";
import classNames from "classnames";
import { Dialog as MaterialDialog } from "@material-ui/core";
import { MultiplyIcon } from "gx-npm-icons";
import { IconButton } from "../icon-button/icon-button.component";
import styles from "./dialog.styles.module.scss";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

type VariantType = "dialog" | "overlay";

type DialogProps = {
  body?: Array<ReactNode> | ReactNode | string;
  bodyScrollable?: boolean;
  closeIcon?: boolean;
  footer?: Array<ReactNode> | ReactNode | string;
  height?: string;
  maxDialogWidth?: string;
  onClose?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  open?: boolean;
  rootClassName?: string;
  title?: Array<ReactNode> | ReactNode | string;
  variant?: VariantType;
};

const Dialog = ({
  body = null,
  bodyScrollable = false,
  closeIcon = false,
  footer = null,
  height,
  maxDialogWidth = "600px",
  onClose = (_e) => {},
  open = false,
  rootClassName = "",
  title = null,
  variant = "dialog",
}: DialogProps) => {
  const bodyHeight = footer ? 255 : 145;

  const handleCloseDialog = (isClosing: boolean, e: React.MouseEvent<HTMLButtonElement>) => {
    if (isClosing) {
      onClose(e);
    }
  };

  return (
    <MaterialDialog
      open={open}
      onClose={(e: React.MouseEvent<HTMLButtonElement>) => handleCloseDialog(!closeIcon, e)}
      PaperProps={{ style: { borderRadius: 8, maxWidth: maxDialogWidth, overflowY: "hidden" } }}
      BackdropProps={{ style: { backgroundColor: "rgb(103, 101, 101, 0.5)" } }}
      className={classNames(styles.root, variant === "dialog" && "gx-dialog", rootClassName)}
    >
      <div className={classNames(styles.container, bodyScrollable && "gx-scrollable")} style={{ height }}>
        <div className={classNames(styles.title, "gx-dialog-title")}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"h4"}>{title}</TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <h4>{title}</h4>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
          {closeIcon && (
            <IconButton
              aria-label={"Close dialog"}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCloseDialog(true, e)}
              focusBackgroundColor="white"
              hoverBackgroundColor="white"
            >
              <MultiplyIcon />
            </IconButton>
          )}
        </div>
        <div className={classNames(styles.body, "gx-dialog-body")} style={{ height: `calc(100% - ${bodyHeight}px)` }}>
          {body}
        </div>
        <div className={classNames(styles.footer, "gx-dialog-footer")}>{footer}</div>
      </div>
    </MaterialDialog>
  );
};

export default Dialog;
