import { colorPalette, weightPalette } from "gx-npm-common-styles";

const blackBerry = colorPalette.interactions.blackBerry.hex;
const blueBerry = colorPalette.interactions.blueBerry.hex;
const cherry = colorPalette.status.cherry.hex;
const coal = colorPalette.neutrals.coal.hex;
const iron = colorPalette.neutrals.iron.hex;
const lightBerry = colorPalette.interactions.lightBerry.hex;
const lightCherry = colorPalette.status.lightCherry.hex;
const mediumCherry = colorPalette.status.mediumCherry.hex;
const poisonCherry = colorPalette.status.poisonCherry.hex;
const silver = colorPalette.neutrals.silver.hex;
const white = colorPalette.basic.white.hex;
const stone = colorPalette.neutrals.stone.hex;
const weightAmtBold = weightPalette.bold.amount;
const weightVarBold = weightPalette.bold.variation;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;

const buttonStyles = {
  root: {
    "&.gx-button": {
      fontSize: "15px",
      fontVariationSettings: weightVarBold,
      fontWeight: weightAmtBold,
      letterSpacing: "0.75px",
      lineHeight: "26px",
      height: "48px",
      padding: "11px 24px",
      borderRadius: "0px",
      zIndex: "1",
      whiteSpace: "nowrap",
      "& span.MuiTouchRipple-rippleVisible": { opacity: 0.2 },
      "& span span.MuiTouchRipple-childPulsate": { all: "unset" },
      "&.btn-primary": {
        backgroundColor: blueBerry,
        color: white,
        "&:hover, &:focus": {
          backgroundColor: blackBerry,
        },
        "&:disabled": {
          backgroundColor: silver,
          color: coal,
        },
      },
      "&.btn-secondary": {
        backgroundColor: white,
        color: blueBerry,
        border: `2px solid ${blueBerry}`,
        padding: "9px 24px",
        "& span.MuiTouchRipple-child": {
          backgroundColor: blueBerry,
        },
        "&:hover, &:focus": {
          backgroundColor: lightBerry,
          border: `2px solid ${blackBerry}`,
          "& .MuiButton-label": {
            color: blackBerry,
          },
        },
        "&:disabled": {
          backgroundColor: white,
          color: iron,
          border: `2px solid ${silver}`,
          "& svg": {
            "& path": {
              fill: iron,
            },
          },
        },
      },
      "&.btn-tertiary": {
        backgroundColor: "transparent",
        color: blueBerry,
        "& span.MuiTouchRipple-child": {
          backgroundColor: blueBerry,
        },
        "&:hover, &:focus": {
          backgroundColor: lightBerry,
          "& .MuiButton-label": {
            color: blackBerry,
          },
          "& svg": {
            "& path": {
              fill: blackBerry,
            },
          },
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: iron,
        },
      },
      "&.primary-destructive-btn": {
        backgroundColor: lightCherry,
        color: poisonCherry,
        "& span.MuiTouchRipple-child": {
          backgroundColor: cherry,
        },
        "&:hover, &:focus": {
          backgroundColor: mediumCherry,
        },
        "&:disabled": {
          backgroundColor: silver,
          color: coal,
        },
      },
      "&.secondary-destructive-btn": {
        backgroundColor: white,
        border: `2px solid ${lightCherry}`,
        color: poisonCherry,
        padding: "9px 24px",
        "& span.MuiTouchRipple-child": {
          backgroundColor: cherry,
        },
        "&:hover, &:focus": {
          backgroundColor: lightCherry,
        },
        "&:disabled": {
          backgroundColor: "transparent",
          border: "2px solid silver",
          color: iron,
        },
      },
      "&.tertiary-destructive-btn": {
        backgroundColor: "transparent",
        border: "none",
        color: poisonCherry,
        "& span.MuiTouchRipple-child": {
          backgroundColor: cherry,
        },
        "&:hover, &:focus": {
          backgroundColor: lightCherry,
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: iron,
        },
      },
    },
    "&.secondary-dark-theme-btn": {
      border: `2px solid ${white}`,
      color: white,
      "&:disabled": {
        border: `2px solid ${stone}`,
        color: stone,
      },
      "&:hover, &:focus": {
        backgroundColor: `rgba(${colorPalette.basic.white.rgba}, 0.2)`,
      },
    },
    "&.gx-button-rounded": {
      borderRadius: "4px",
    },
  },
  rootGCOM3695: {
    "&.gx-button": {
      "&.btn-primary": {
        backgroundColor: defaultCta,
        "&:focus": { backgroundColor: selectedCta },
        "&:hover": {
          backgroundColor: hoverCta,
        },
        "&:disabled": {
          backgroundColor: silver,
        },
      },
      "&.btn-secondary": {
        border: `2px solid ${defaultCta}`,
        color: defaultCta,
        "& span.MuiTouchRipple-child": {
          backgroundColor: defaultCta,
        },
        "&:focus": {
          border: `2px solid ${selectedCta}`,
          "& .MuiButton-label": {
            color: selectedCta,
          },
        },
        "&:hover": {
          border: `2px solid ${hoverCta}`,
          "& .MuiButton-label": {
            color: hoverCta,
          },
        },
        "&:disabled": {
          backgroundColor: white,
        },
      },
      "&.btn-tertiary": {
        backgroundColor: "transparent",
        color: defaultCta,
        "& span.MuiTouchRipple-child": {
          backgroundColor: defaultCta,
        },
        "&:hover": {
          backgroundColor: lightBerry,
          "& .MuiButton-label": {
            color: hoverCta,
          },
          "& svg": {
            "& path": {
              fill: hoverCta,
            },
          },
        },
        "&:focus": {
          backgroundColor: lightBerry,
          "& .MuiButton-label": {
            color: selectedCta,
          },
          "& svg": {
            "& path": {
              fill: selectedCta,
            },
          },
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: iron,
        },
      },
    },
  },
};

export { buttonStyles };
