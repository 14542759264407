import { colorPalette } from "gx-npm-common-styles";

const loaderStyles = {
  top: {
    left: "50%",
    position: "absolute" as const,
    top: "50%",
    "&.MuiCircularProgress-colorPrimary": {
      color: colorPalette.interactions.blueBerry.hex,
    },
    "&$btnLoader": {
      marginLeft: -12,
      marginTop: -12,
      "&.MuiCircularProgress-colorPrimary": {
        color: colorPalette.status.poisonCherry.hex,
      },
      "&.btn-primary.MuiCircularProgress-colorPrimary": {
        color: colorPalette.basic.white.hex,
      },
      "&.btn-secondary.MuiCircularProgress-colorPrimary": {
        color: colorPalette.interactions.blueBerry.hex,
      },
      "&.btn-tertiary.MuiCircularProgress-colorPrimary": {
        color: colorPalette.interactions.blueBerry.hex,
      },
    },
  },
  btnLoader: {},
  topGCOM3695: {
    "&.MuiCircularProgress-colorPrimary": {
      color: colorPalette.interactions.defaultCta.hex,
    },
    "&$btnLoader": {
      "&.btn-secondary.MuiCircularProgress-colorPrimary": {
        color: colorPalette.interactions.defaultCta.hex,
      },
      "&.btn-tertiary.MuiCircularProgress-colorPrimary": {
        color: colorPalette.interactions.defaultCta.hex,
      },
    },
  },
};

export { loaderStyles };
