import { colorPalette } from "gx-npm-common-styles";

const hoverBgColor = `rgba(${colorPalette.interactions.lightBerry.rgb}, 0.5)`;
const focusBgColor = `rgba(${colorPalette.interactions.lightBerry.rgb}, 0.9)`;
const activeBgColor = `rgba(${colorPalette.interactions.mildBerry.rgb}, 0.6)`;
const selectedBarBgColor = `rgba(${colorPalette.interactions.blueBerry.rgb}, 0.38)`;
const selectedKnobColor = colorPalette.interactions.blueBerry.hex;
const unSelectedKnobColor = colorPalette.neutrals.quartz.hex;
const unSelectedDisabledBarBgColor = `rgba(${colorPalette.neutrals.coal.rgb}, 0.38)`;
const unSelectedBarBgColor = colorPalette.neutrals.coal.hex;

const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;

const switchStyles = {
  disabledRoot: {
    opacity: "50%",
  },
  switchBase: {
    color: unSelectedKnobColor,
    "&:hover": {
      backgroundColor: hoverBgColor,
    },
    "&:focus": {
      backgroundColor: focusBgColor,
    },
    "&:active": {
      backgroundColor: activeBgColor,
    },
    "&$disabled": {
      color: unSelectedKnobColor,
      "&.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
        opacity: "unset",
      },
      "& + $track": {
        backgroundColor: unSelectedDisabledBarBgColor,
      },
    },
    "&$checked": {
      color: selectedKnobColor,

      "&:hover": {
        backgroundColor: hoverBgColor,
      },
      "&:focus": {
        backgroundColor: focusBgColor,
      },
      "&:active": {
        backgroundColor: activeBgColor,
      },
      "& + $track": {
        backgroundColor: selectedBarBgColor,
      },
      "&$disabled": {
        "&.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
          opacity: "unset",
        },
        "& + $track": {
          backgroundColor: selectedBarBgColor,
        },
      },
    },
  },
  thumb: {
    boxShadow: "0px 1px 1px rgba(20, 19, 18, 0.38)",
    height: "20px",
    width: "20px",
  },
  track: {
    backgroundColor: unSelectedBarBgColor,
  },
  checked: {
    /* referenced class */
  },
  disabled: {
    /* referenced class */
  },
  root: {
    /* referenced class */
  },

  switchBaseGCOM3695: {
    "&$checked": {
      color: defaultCta,

      "&:hover": {
        color: hoverCta,
      },
      "&:focus": {
        color: selectedCta,
      },
      "&:active": {
        color: selectedCta,
      },
      "& + $track": {
        backgroundColor: selectedKnobColor,
      },
      "&$disabled": {
        "& + $track": {
          backgroundColor: selectedKnobColor,
        },
      },
    },
  },
};

export default switchStyles;
