import { Fragment } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import NoDocumentsImage from "../../assets/images/no-documents.svg";
import { Header, Paragraph } from "gx-npm-ui";
import styles from "./no-documents.styles.module.scss";
import InfoOutlineIcon from "./info-outline.icon";
import VectorIcon from "./vector.icon";
import { UserViews, UserViewTypes } from "../../app.types";

type NoDocumentsSectionProps = {
  userView?: UserViewTypes;
};

const NoDocumentsSection = ({ userView }: NoDocumentsSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.noDocumentsContainer)}>
      <img alt="no documents" className={classNames(styles.noDocumentsImage)} src={NoDocumentsImage} />
      {userView === UserViews.RECIPIENT && (
        <Header rootClassName={classNames(styles.noDocumentsText)} type="h4">
          {t("You have no document requests")}
        </Header>
      )}
      {userView === UserViews.EVALUATOR && (
        <Fragment>
          <Header rootClassName={classNames(styles.noDocumentsText)} type="h4">
            {t("No vendor documents provided.")}
          </Header>
          <div className={classNames(styles.noDocumentsIconContainer)}>
            <VectorIcon />
            <div className={classNames(styles.noDocumentsCenteredIcon)}>
              <InfoOutlineIcon />
            </div>
            <VectorIcon />
          </div>
          <Paragraph rootClassName={classNames(styles.noDocumentsSubtext)} type="p4">
            {t("Vendor responses in BuySmart are not reviewed by Gartner Experts")}
            {` `}
            {t("and do not necessarily represent the views or opinions of Gartner.")}
          </Paragraph>
        </Fragment>
      )}
    </div>
  );
};

export default NoDocumentsSection;
