import React from "react";
import { DownloadOnTheAppStoreBadgeIcon, GetItOnGooglePlayBadgeIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./gartner-footer.styles";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const useStyles = makeStyles(() => styles);

export const GartnerFooter = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div>
          <div className={classes.linkGroup}>
            <a href="https://www.gartner.com/en/about/policies/overview" className={classes.link}>
              {t("POLICIES")}
            </a>
            <a href="https://www.gartner.com/en/about/policies/privacy" className={classes.link}>
              {t("PRIVACY POLICY")}
            </a>
            <a href="https://www.gartner.com/en/about/policies/terms-of-use" className={classes.link}>
              {t("TERMS OF USE")}
            </a>
            <a href="https://www.gartner.com/en/about/ombuds" className={classes.link}>
              {t("OMBUDS")}
            </a>
          </div>
          <div className={classes.copyright}>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent element={"span"} rootClassName={classes.copyrightText}>
                  &copy; {t("2024 Gartner, Inc. and/or its affiliates. All rights reserved.")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <span className={classes.copyrightText}>
                  &copy; {t("2024 Gartner, Inc. and/or its affiliates. All rights reserved.")}
                </span>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          </div>
        </div>
        <div className={classes.appSection}>
          <h5 className={classes.appTitle}>Get the App</h5>
          <div className={classes.badgeContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.gartner.mygartner&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              aria-label={t("Get the Gartner App on Google play")}
            >
              <GetItOnGooglePlayBadgeIcon />
            </a>
            <a
              aria-label={t("Get the Gartner App on Apple store")}
              href={"https://apps.apple.com/us/app/my-gartner-mobile/id1202220943?itsct=apps_box_link&itscg=30200"}
            >
              <DownloadOnTheAppStoreBadgeIcon />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
