import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch as MUISwitch, makeStyles } from "@material-ui/core";
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import styles from "./switch.styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";
import classnames from "classnames";

const generateClassName = createGenerateClassName({
  seed: "Switch-UI",
});

const propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleSwitchToggle: PropTypes.func,
  onToggle: PropTypes.func,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
/**
checked - flag for turning switch on/off. defaults to false
disabled - flag enable/disable switch. defaults to false
handleSwitchToggle - DEPRECATED callback function to execute when switch toggles
onToggle - callback function to execute when switch toggles
 */
const Switch = ({
  checked = false,
  disabled = false,
  handleSwitchToggle = (_isChecked) => {},
  onToggle = (_isChecked) => {},
  rootClassName = "",
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleChange = (event) => {
    setValue(event.target.checked);
    handleSwitchToggle(event.target.checked);
    onToggle(event.target.checked);
  };
  return (
    <StylesProvider generateClassName={generateClassName}>
      <MUISwitch
        checked={value}
        className={rootClassName}
        classes={{
          checked: classes.checked,
          disabled: classes.disabled,
          root: disabled ? classes.disabledRoot : classes.root,
          switchBase: classnames(classes.switchBase, isFFGCOM3695 && classes.switchBaseGCOM3695),
          thumb: classes.thumb,
          track: classes.track,
        }}
        disabled={disabled}
        disableRipple
        onChange={handleChange}
      />
    </StylesProvider>
  );
};

Switch.propTypes = propTypes;
export { Switch };
