import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import {
  SnackbarBanner,
  Header,
  Paragraph,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOn,
  FeatureFlagBooleanOff,
  TypographyComponent,
} from "gx-npm-ui";
import { SurveyDocumentsAppContext } from "../../app.context";
import DocumentCard from "./document-card.component";
import styles from "./document-cards.styles.module.scss";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const DocumentCardsSection = () => {
  const { t } = useTranslation();
  const {
    docsTotal,
    docsUploaded,
    documents,
    setShowErrorSnackBar,
    setShowSuccessSnackBar,
    showErrorSnackBar,
    showSuccessSnackBar,
    userView,
  } = useContext(SurveyDocumentsAppContext);

  return (
    <div className={classNames(styles.docsSectionContainer)}>
      <div className={classNames(styles.titleContainer)}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent element={"p"}>{t("Documents uploaded")}</TypographyComponent>
            <TypographyComponent styling={"h5"} rootClassName={classNames(styles.docsUploaded)}>
              {docsUploaded} / {docsTotal}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph>{t("Documents uploaded")}</Paragraph>
            <Header rootClassName={classNames(styles.docsUploaded)} type="h5">
              {docsUploaded} / {docsTotal}
            </Header>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      <div className={classNames(styles.cardsContainer)}>
        {documents.map((doc) => {
          const isUploaded = !doc.isSkipped && !!doc.uploadedDocName;

          return (
            <DocumentCard
              id={doc.id}
              isSkipped={doc.isSkipped}
              isUploaded={isUploaded}
              key={doc.id}
              requestedDocName={doc.requestedDocName}
              uploadedDocBy={doc.uploadedDocBy}
              uploadedDocDate={doc.uploadedDocDate}
              uploadedDocName={doc.uploadedDocName}
              userView={userView}
            />
          );
        })}
      </div>
      <SnackbarBanner
        isOpen={showErrorSnackBar}
        setIsOpen={setShowErrorSnackBar}
        type="ERROR"
        isDefaultErrorMessage={true}
      />
      <SnackbarBanner
        isOpen={showSuccessSnackBar}
        message={t("Your document was successfully added.")}
        setIsOpen={() => setShowSuccessSnackBar(false)}
        type="SUCCESS"
      />
    </div>
  );
};

export default DocumentCardsSection;
