import { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { handleEvent } from "gx-npm-lib";
import { Paragraph } from "../typography/paragraph/paragraph.component";
import { textAreaStyles } from "./styles";
import { baseTextAreaFlavors as flavorTypes } from "./types";
import { GCOM_3695_ctaButtonColorUpdate, GCOM_3606__fontUpdate } from "../feature-flags";
import {
  useFeatureFlag,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
} from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const propTypes = {
  areaRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  flavor: PropTypes.string,
  handleAutoSave: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  bottomHelperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  maxAllowedChars: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
  rootClassName: PropTypes.string,
  showCharCount: PropTypes.bool,
  showCharCountOnFocus: PropTypes.bool,
  showCharCountOnHover: PropTypes.bool,
  showVerticalResize: PropTypes.bool,
  value: PropTypes.string,
  isError: PropTypes.bool,
};
const useStyles = makeStyles(() => textAreaStyles);
const BaseTextArea = ({
  areaRef = null,
  autoFocus = false,
  disabled = false,
  flavor = "",
  handleAutoSave = (..._args) => {},
  helperText = "",
  bottomHelperText = "",
  maxAllowedChars = 0,
  maxRows = null,
  minRows = 1,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onMouseEnter,
  placeholder,
  required = false,
  rootClassName = "",
  showCharCount = false,
  showCharCountOnFocus = false,
  showCharCountOnHover = false,
  showVerticalResize = false,
  value = "",
  isError = false,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const [display, setDisplay] = useState("");
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [initialValue, setInitialValue] = useState("");
  const [textAreaId, setTextAreaId] = useState("");

  useEffect(() => {
    setTextAreaId(uuidV4());
  }, []);

  useEffect(() => {
    setDisplay(value || "");
  }, [value]);

  const handleBlur = (event) => {
    handleEvent(onBlur, event);
    const newValue = event?.target?.value || "";
    if (initialValue !== newValue) {
      handleEvent(handleAutoSave, newValue);
    }
    setFocus(false);
    setInitialValue(null);
  };

  const handleChange = (event) => {
    const newValue = event?.target?.value || "";
    setDisplay(newValue);
    handleEvent(onChange, event);
  };

  const handleFocus = (event) => {
    setFocus(true);
    setInitialValue(value);
    handleEvent(onFocus, event);
  };

  const handleOnMouseOut = () => {
    setHover(false);
  };

  const handleOnMouseOver = () => {
    setHover(true);
  };

  return (
    <div
      className={classNames(
        "gx-base-textarea",
        rootClassName,
        classes.baseTextAreaRoot,
        isFFGCOM3695 && classes.baseTextAreaRootGCOM3695,
        display && "filled",
        // placeholder - type does not exist, will be
        flavor === flavorTypes.default && classes.baseTextAreaDefaultFlavor,
        flavor === flavorTypes.outlined &&
          classNames(classes.baseTextAreaOutlinedFlavor, isFFGCOM3695 && classes.baseTextAreaOutlinedFlavorGCOM3695),
        focus && "focus",
        hover && "hover",
        required && "required",
        (showCharCount || showCharCountOnFocus || showCharCountOnHover) && "char-count",
        showVerticalResize && "resize-vertical",
        isError && "isError",
        "textarea-rounded",
        classes.newFont
      )}
    >
      <TextareaAutosize
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxAllowedChars > 0 ? maxAllowedChars : null}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={(e) => handleEvent(onClick, e)}
        onFocus={handleFocus}
        onKeyDown={(e) => handleEvent(onKeyDown, e)}
        onMouseEnter={(e) => handleEvent(onMouseEnter, e)}
        onMouseOut={handleOnMouseOut}
        onMouseOver={handleOnMouseOver}
        placeholder={placeholder}
        ref={areaRef}
        rowsMax={maxRows}
        rowsMin={minRows}
        value={display}
        id={textAreaId}
      />
      {bottomHelperText && (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent
              rootClassName={classNames(
                classes.bottomHelperText,
                isError && classes.bottomHelperTextError,
                "gx-base-textarea-bottom-helper-text"
              )}
              styling={"p4"}
            >
              {bottomHelperText}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <Paragraph
              rootClassName={classNames(
                classes.bottomHelperText,
                isError && classes.bottomHelperTextError,
                "gx-base-textarea-bottom-helper-text"
              )}
              type="p4"
            >
              {bottomHelperText}
            </Paragraph>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      )}
      {helperText && (
        <label htmlFor={textAreaId} className={classNames(classes.helperText, "gx-base-textarea-helper-text")}>
          {helperText}
        </label>
      )}
      {(showCharCount || (showCharCountOnFocus && focus) || (showCharCountOnHover && hover)) && (
        <label htmlFor={textAreaId} className={classNames(classes.charCount, "gx-base-textarea-char-count")}>
          {`${display.length}`}
          {maxAllowedChars ? ` / ${maxAllowedChars}` : ""}
        </label>
      )}
    </div>
  );
};

BaseTextArea.propTypes = propTypes;
export default BaseTextArea;
