import classnames from "classnames";
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel } from "@material-ui/core";
import styles from "./checkbox.styles.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../../lib/feature-flag";
import { useFeatureFlag } from "gx-npm-ui";

type VariantCheckboxTypes = "dark-theme" | "default";

interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  rootClassName?: string;
  variant?: VariantCheckboxTypes;
}

/**
 * @param {{
 *   label: string,
 *   rootClassName: string,
 *   variant: VariantCheckboxTypes
 * }} props
 * @var label - string or object to pass to mui form control label if used
 * @var rootClassName - css class to determine style
 * @var variant - applies styles based on variant type, options are darkTheme
 * @returns mui checkbox wrapper
 */

const Checkbox = ({ label, rootClassName, variant = "default", ...props }: CheckboxProps) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const renderCheckbox = () => {
    return (
      <MuiCheckbox
        {...props}
        aria-label="checkbox"
        className={classnames("gx-checkbox", styles.checkbox, isFFGCOM3695 && styles.checkboxGCOM3696, styles[variant])}
      />
    );
  };

  return (
    <div className={classnames("gx-checkbox-container", rootClassName && rootClassName)}>
      {label ? (
        <FormControlLabel
          classes={{
            label: classnames("gx-checkbox-label", styles.label, styles[variant]),
          }}
          control={renderCheckbox()}
          label={label}
        />
      ) : (
        renderCheckbox()
      )}
    </div>
  );
};

export default Checkbox;
