import { Fragment, useContext, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Loader, TextLink, Paragraph } from "gx-npm-ui";
import { ExclamationCircledIcon } from "gx-npm-icons";
import DocumentCheckboxSkip from "./components/checkbox-skip/document-card-checkbox-skip.component";
import DocumentFileUpload from "./document-card-file-upload.component";
import { SurveyDocumentsAppContext } from "../../app.context";
import { FileUploadErrors, UserViews } from "../../app.types";
import styles from "./document-card.styles.module.scss";
import { UUID } from "gx-npm-lib";

type DocumentCardNotUploadedProps = {
  id: UUID;
  isSkipped: boolean;
  requestedDocName: string;
};

const DocumentCardNotUploaded = ({ id, isSkipped, requestedDocName }: DocumentCardNotUploadedProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidFile, setIsValidFile] = useState<boolean | undefined>();

  const {
    docsUploaded,
    documents,
    setDocsUploaded,
    setDocuments,
    setShowErrorSnackBar,
    setShowSuccessSnackBar,
    userView,
    isPreviewMode,
  } = useContext(SurveyDocumentsAppContext);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickUpload = () => {
    if (inputRef.current && !isPreviewMode) {
      inputRef.current.click();
    }
  };

  const handleOnError = (errorType: FileUploadErrors) => {
    if (errorType === FileUploadErrors.INVALID_FILE) {
      setIsValidFile(false);
    } else {
      setShowErrorSnackBar(true);
    }
  };

  const handleOnLoad = (loading: boolean) => {
    setIsLoading(loading);
    if (loading) {
      setIsValidFile(true);
    }
  };

  const handleSuccess = (data: { uploadedDocBy: string; uploadedDocDate: string; uploadedDocName: string }) => {
    const { uploadedDocBy, uploadedDocDate, uploadedDocName } = data;

    setDocsUploaded(docsUploaded + 1);

    setDocuments(
      documents.map((document) => {
        if (document.id === id) {
          return {
            ...document,
            uploadedDocBy,
            uploadedDocDate,
            uploadedDocName,
          };
        }
        return document;
      })
    );

    setShowSuccessSnackBar(true);
  };

  const renderNotUploaded = () => {
    if (isLoading) {
      return (
        <div aria-label={t("uploading document")}>
          <Loader rootClassName={styles.docLoader} />
        </div>
      );
    }
    if (isValidFile === false) {
      return (
        <Fragment>
          <div className={classNames(styles.validationFailedIcon)}>
            <ExclamationCircledIcon />
          </div>
          <div>
            <div className={classNames(styles.uploadText)}>
              <Paragraph rootClassName={classNames(styles.notUploadedTitle)}>
                {t("File upload failed")}
                {` `}
              </Paragraph>
              <TextLink
                onClick={handleClickUpload}
                rootClassName={classNames(styles.uploadLink)}
                text={t("choose another file")}
              />
            </div>
            <ul className={classNames(styles.errorChecklist)}>
              <li>
                <Paragraph rootClassName={classNames(styles.uploadedDocDesc)} type="p4">
                  {t("Check your file type (.pdf, .xlsx, .xls)")}
                </Paragraph>
              </li>
              <li>
                <Paragraph rootClassName={classNames(styles.uploadedDocDesc)} type="p4">
                  {t("Make sure your file is under 15MB")}
                </Paragraph>
              </li>
            </ul>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={classNames(styles.uploadText)}>
          <Paragraph rootClassName={classNames(styles.notUploadedTitle)}>{`${t("Drop a file here or")} `}</Paragraph>
          <TextLink
            onClick={handleClickUpload}
            rootClassName={classNames(styles.uploadLink)}
            text={t("choose a file")}
          />
        </div>
        <Paragraph rootClassName={classNames(styles.uploadedDocDesc)} type="p4">
          {t("File types .pdf, .xlsx, .xls.")}
        </Paragraph>
        <Paragraph rootClassName={classNames(styles.uploadedDocDesc)} type="p4">
          {t("Max file size 15MB")}
        </Paragraph>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Paragraph boldness="bold" rootClassName={classNames(styles.requestedDocName)} type="p2">
        {requestedDocName}
      </Paragraph>
      {userView === UserViews.RECIPIENT ? (
        <Fragment>
          <DocumentFileUpload
            id={id}
            onError={handleOnError}
            onLoad={handleOnLoad}
            onSuccess={handleSuccess}
            ref={inputRef}
          >
            <div
              className={classNames(
                styles.docCardInner,
                isValidFile === false ? styles.validationFailed : styles.notUploaded
              )}
            >
              {renderNotUploaded()}
            </div>
          </DocumentFileUpload>
          <DocumentCheckboxSkip id={id} isSkipped={isSkipped} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classNames(styles.notUploadedDivider)} />
          <div className={classNames(styles.docCardInner, styles.notUploadedEvaluator)}>
            <Paragraph boldness="semi" rootClassName={classNames(styles.notUploadedDocName)} type="p4">
              {t("Document not uploaded yet")}
            </Paragraph>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DocumentCardNotUploaded;
