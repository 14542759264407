import { colorPalette } from "gx-npm-common-styles";

const slideInOverlayStyles = {
  containerBody: {
    height: "calc(100vh - 72px)",
    overflowY: "auto",
    padding: "56px",
    position: "relative",
    transition: "padding 0.5s",
    "&$darth": {
      backgroundColor: colorPalette.brand.gartnerBlue.hex,
    },
    "@media (min-width: 1440px)": {
      padding: "56px 148px",
    },
    "&$statusOverlayNav": {
      "@media (min-width: 1440px)": {
        padding: "56px 148px 56px 326px",
      },
      "@media (max-width: 1439px)": {
        paddingLeft: "236px",
      },
    },
  },
  containerBodyGCOM3695: {
    "&$darth": {
      backgroundColor: "rgba(0, 0, 53, 1)",
    },
  },
  containerHeader: {
    alignItems: "center",
    backgroundColor: colorPalette.neutrals.quartz.hex,
    display: "flex",
    height: "72px",
    minHeight: "72px",
    paddingLeft: "48px",
    paddingRight: "24px",
    position: "sticky",
    width: "100%",
    "&$darth": {
      backgroundColor: colorPalette.brand.gartnerDarkBlue.hex,
      // svg color
      "& svg": {
        fill: colorPalette.basic.white.hex,
      },
    },
  },
  containerHeaderGCOM3695: {
    "&$darth": {
      backgroundColor: "rgb(0, 11, 111)",
    },
  },
  headerBtn: {
    alignItems: "center",
    color: colorPalette.interactions.blueBerry.hex,
    display: "flex",
    paddingRight: "36px",
    "&:hover": {
      cursor: "pointer",
    },
    "&$darth": {
      color: colorPalette.basic.white.hex,
    },
  },
  headerBtnGCOM3695: {
    color: colorPalette.interactions.defaultCta.hex,
  },
  btnStartText: {
    margin: "0 4px",
  },
  btnText: {
    alignItems: "center",
    display: "flex",
    fontSize: "15px",
    letterSpacing: "0.75px",
    lineHeight: "26px",
    textTransform: "uppercase",
  },
  headerTitle: {
    alignItems: "center",
    borderLeft: `1px solid ${colorPalette.neutrals.silver.hex}`,
    color: colorPalette.neutrals.iron.hex,
    display: "flex",
    height: "100%",
    paddingLeft: "24px",
  },
  headerLogo: {
    alignItems: "center",
    display: "flex",
    marginLeft: "auto",
    marginRight: 0,
    order: 2,
  },
  // Mui specific classes passed into drawer
  root: {
    "& .MuiBackdrop-root": {
      // adding 80 at end of default hex adds the alpha value of 128 to give opacity
      backgroundColor: `${colorPalette.neutrals.iron.hex}80`,
    },
  },
  paper: {
    maxWidth: "1285px",
    width: "85%",
  },
  statusOverlayNav: {},
  darth: {},
};

export { slideInOverlayStyles };
