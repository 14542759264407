import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ArrowMoreIcon } from "gx-npm-icons";
import { makeStyles } from "@material-ui/core/styles";
import { textLinkStyles } from "./styles";
import { handleEvent } from "gx-npm-lib";
import { GCOM_3695_ctaButtonColorUpdate, GCOM_3606__fontUpdate } from "../feature-flags";
import {
  useFeatureFlag,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
} from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";

const variantType = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  dark: "dark",
};
const propTypes = {
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(variantType)),
};
const useStyles = makeStyles(() => textLinkStyles);
const TextLink = ({
  ariaLabel = "",
  disabled = false,
  href = "",
  onClick = (..._args) => {},
  rootClassName = "",
  target = "",
  text = "",
  variant = variantType.primary,
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  return (
    <a
      aria-label={ariaLabel || null}
      disabled={disabled}
      className={classNames(
        "gx-text-link",
        rootClassName,
        classes.textLink,
        isFFGCOM3695 && classes.textLinkGCOM3695,
        disabled && "disabled",
        variantType[variant] ? variant : variantType.primary
      )}
      href={href || null}
      onClick={(e) => handleEvent(onClick, e)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleEvent(onClick, e);
        }
      }}
      rel={target === "_blank" ? "noreferrer" : null}
      target={target || null}
      tabIndex={0}
    >
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={variant === variantType.secondary ? "p4" : "p3"}>{text}</TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={variant === variantType.secondary ? "p4" : "p3"}>{text}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
      {variant === variantType.secondary && <ArrowMoreIcon />}
    </a>
  );
};

TextLink.propTypes = propTypes;
export { TextLink, variantType as variantTypeTextLink };
