import classNames from "classnames";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { buttonStyles as styles } from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";

const propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const Button = ({
  ariaLabel = "",
  children,
  disabled = false,
  href = "",
  onClick = (..._args) => {},
  rootClassName = "btn-tertiary",
}) => {
  const classes = useStyles();
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  return (
    <MuiButton
      aria-label={ariaLabel || null}
      className={classNames(
        "gx-button",
        rootClassName,
        classes.root,
        "gx-button-rounded",
        isFFGCOM3695 && classes.rootGCOM3695
      )}
      color="primary"
      disabled={disabled}
      disableElevation={true}
      href={href || null}
      onClick={onClick}
      variant="text"
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = propTypes;
export { Button };
