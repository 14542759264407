import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { menuItemStyles as styles } from "./styles";
import { TooltipV2 } from "../tooltip-v2";
const propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  isPreventingDefault: PropTypes.bool,
  link: PropTypes.string,
  menuListIcon: PropTypes.node,
  name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipOptions: PropTypes.object,
  listItemClassName: PropTypes.string,
  isDynamicWidth: PropTypes.bool,
};
const useStyles = makeStyles(() => styles);
const MenuItem = ({
  disabled = false,
  index = -1,
  isPreventingDefault = true,
  link = "",
  menuListIcon = null,
  name = "",
  onClick = null,
  rootClassName = "",
  showTooltip = false,
  tooltipOptions = {},
  listItemClassName = "",
  isDynamicWidth = true,
}) => {
  const classes = useStyles();

  const handleClick = (event) => {
    if (isPreventingDefault) {
      event.preventDefault();
    }
    if (!disabled) {
      handleEvent(onClick, index);
    }
  };
  const renderContent = () => {
    const content = (
      <a
        className={classNames(classes.p3, disabled && classes.disabled, listItemClassName)}
        href={link ? link : null}
        onClick={(e) => handleClick(e)}
      >
        {name}
        {menuListIcon}
      </a>
    );
    return showTooltip ? (
      <TooltipV2 {...tooltipOptions}>
        <div>{content}</div>
      </TooltipV2>
    ) : (
      content
    );
  };
  return (
    !!name && (
      <li
        className={classNames(
          classes.menuItem,
          "gx-popover-menu-item",
          isDynamicWidth && "gx-popover-menu-item-auto-width",
          rootClassName,
          menuListIcon && "gx-popover-menu-item-has-icon"
        )}
      >
        {renderContent()}
      </li>
    )
  );
};

MenuItem.propTypes = propTypes;
export default MenuItem;
