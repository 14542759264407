import classNames from "classnames";
import { useTranslation } from "react-i18next";
import DocumentCardNotUploaded from "./document-card-not-uploaded.component";
import DocumentCardSkip from "./document-card-skip.component";
import DocumentCardUploaded from "./document-card-uploaded.component";
import { UserViews, UserViewTypes } from "../../app.types";
import styles from "./document-card.styles.module.scss";
import { UUID } from "gx-npm-lib";

type DocumentCardProps = {
  id: UUID;
  isSkipped: boolean;
  isUploaded: boolean;
  requestedDocName: string;
  uploadedDocBy: string;
  uploadedDocDate: string;
  uploadedDocName: string;
  userView?: UserViewTypes;
};

const DocumentCard = ({
  id,
  isSkipped = false,
  isUploaded = false,
  requestedDocName,
  uploadedDocBy,
  uploadedDocDate,
  uploadedDocName,
  userView,
}: DocumentCardProps) => {
  const { t } = useTranslation();

  let cardType = "";

  if (isSkipped) {
    cardType = t("skipped");
  } else if (isUploaded) {
    cardType = t("uploaded");
  } else {
    cardType = t("not uploaded");
  }

  const renderCardInner = () => {
    if (isSkipped) {
      return <DocumentCardSkip id={id} isSkipped={isSkipped} requestedDocName={requestedDocName} userView={userView} />;
    } else if (isUploaded) {
      return (
        <DocumentCardUploaded
          id={id}
          isSkipped={isSkipped}
          requestedDocName={requestedDocName}
          uploadedDocBy={uploadedDocBy}
          uploadedDocDate={uploadedDocDate}
          uploadedDocName={uploadedDocName}
        />
      );
    } else {
      return <DocumentCardNotUploaded id={id} isSkipped={isSkipped} requestedDocName={requestedDocName} />;
    }
  };

  return (
    <div
      key={id}
      aria-label={`${cardType} ${t("document card")}`}
      className={classNames(
        styles.docCard,
        isSkipped && styles.skipped,
        userView === UserViews.EVALUATOR && styles.evaluator
      )}
    >
      {renderCardInner()}
    </div>
  );
};

export default DocumentCard;
