import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { RestoreArchivedFolderIcon, RestoreDeletedTrashcanIcon } from "gx-npm-icons";
import { handleEvent, InitState, isValidDate } from "gx-npm-lib";
import PipeSvg from "./pipeSvg";
import { restoreBannerStyles as styles } from "./styles";
import {
  GCOM_286_3754_updateColorBanner,
  GCOM_3606__fontUpdate,
  GCOM_3695_ctaButtonColorUpdate,
} from "../feature-flags";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../featureFlags";
import { TypographyComponent } from "../typography/typography.component";
import { colorPalette } from "gx-npm-common-styles";

const formatDate = (date) => {
  let display = "";
  if (isValidDate(date)) {
    const day = "numeric";
    const month = "long";
    const timeZone = "UTC";
    const year = "numeric";
    const options = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};

const bannerCopy = {
  button: {
    [InitState.ARCHIVED]: "Unarchive",
    [InitState.DELETED]: "Restore from trash",
  },
  date: {
    [InitState.ARCHIVED]: "Archived",
    [InitState.DELETED]: "Deleted",
  },
};

const propTypes = {
  actionDate: PropTypes.string,
  initState: PropTypes.oneOf([InitState.ARCHIVED, InitState.DELETED]),
  isRestorable: PropTypes.bool,
  onClickAction: PropTypes.func,
};

const useStyles = makeStyles(() => styles);

const RestoreAlertBanner = ({
  actionDate = "",
  initState = InitState.DELETED,
  isRestorable = false,
  onClickAction = () => {},
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const displayDate = formatDate(actionDate);
  const isGCOM3754FFOn = useFeatureFlag(GCOM_286_3754_updateColorBanner);
  const classes = useStyles();
  return (
    <section
      aria-label="restore evaluation banner"
      className={classNames(classes.restoreBannerRoot, isGCOM3754FFOn && classes.restoreBannerRootGCOM3754)}
    >
      <div className={classNames(classes.restoreBannerWrapper)}>
        <div className={classNames(classes.bannerDate)}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent rootClassName={classNames(classes.textDate)} boldness={"medium"} styling={"p3"}>
                {bannerCopy.date[initState]}
                {displayDate && ` on ${displayDate}`}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p className={classNames(classes.textDate, "p3", "medium-bold")}>
                {bannerCopy.date[initState]}
                {displayDate && ` on ${displayDate}`}
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        {isRestorable && (
          <div className={classNames(classes.bannerButtonContainer)}>
            <span className={classNames(classes.pipeSvg)}>
              <PipeSvg />
            </span>
            <div
              className={classNames(classes.bannerRestoreButton)}
              onClick={() => handleEvent(onClickAction)}
              onKeyDown={(e) => e.key === "Enter" && handleEvent(onClickAction)}
              role="button"
              tabIndex={0}
              aria-label="Restore Button"
            >
              <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                <FeatureFlagBooleanOn>
                  <TypographyComponent
                    rootClassName={classNames(classes.textButton, isFFGCOM3695 && classes.textButtonGCOM3695)}
                    boldness={"medium"}
                    styling={"p3"}
                  >
                    {initState === InitState.ARCHIVED && (
                      <RestoreArchivedFolderIcon
                        {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })}
                      />
                    )}
                    {initState === InitState.DELETED && (
                      <RestoreDeletedTrashcanIcon
                        {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })}
                      />
                    )}
                    {bannerCopy.button[initState]}
                  </TypographyComponent>
                </FeatureFlagBooleanOn>
                <FeatureFlagBooleanOff>
                  <p
                    className={classNames(
                      classes.textButton,
                      isFFGCOM3695 && classes.textButtonGCOM3695,
                      "p3",
                      "semi-bold"
                    )}
                  >
                    {initState === InitState.ARCHIVED && (
                      <RestoreArchivedFolderIcon
                        {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })}
                      />
                    )}
                    {initState === InitState.DELETED && (
                      <RestoreDeletedTrashcanIcon
                        {...(isFFGCOM3695 && { fillPath: colorPalette.interactions.defaultCta.hex })}
                      />
                    )}
                    {bannerCopy.button[initState]}
                  </p>
                </FeatureFlagBooleanOff>
              </FeatureFlagBooleanContainer>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

RestoreAlertBanner.propTypes = propTypes;
export default RestoreAlertBanner;
