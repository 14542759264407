import { colorPalette, weightPalette } from "gx-npm-common-styles";

const blueBerryHex = colorPalette.interactions.blueBerry.hex;
const basicWhiteHex = colorPalette.basic.white.hex;
const neutralsIronHex = colorPalette.neutrals.iron.hex;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const menuItemStyles = {
  menuItem: {
    "&.gx-popover-menu-item-auto-width": {
      paddingRight: 36,
      minWidth: "112px",
      "&.gx-popover-menu-item-has-icon": {
        paddingRight: 0,
        "& svg": {
          float: "none",
          marginRight: "8px",
          marginLeft: "4px",
        },
      },
    },
    "&.gx-popover-menu-item-has-icon": {
      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& svg": {
        float: "none",
        marginRight: "10px",
      },
    },
    "& $disabled": {
      color: neutralsIronHex,
    },
  },
  disabled: {},
  p3: {
    fontSize: "14px",
    fontVariationSettings: "'wght' 500",
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "21px",
  },
};

const optionListStyles = {
  optionsList: {
    backgroundColor: basicWhiteHex,
    border: `2px solid ${colorPalette.neutrals.silver.hex}`,
    left: "-98px",
    position: "absolute",
    top: "52px",
    "&.menu-box-rounded": {
      borderRadius: "4px",
    },
    "&.gx-menu-list-aligned-left-bottom": {
      left: 0,
      top: 53,
      "&.gx-menu-list-select": { left: -2 },
    },
    "&.gx-menu-list-aligned-right-bottom": {
      left: "auto",
      right: 0,
      top: 53,
      "&.gx-menu-list-select": { right: -2 },
    },
    "&.gx-menu-list-aligned-left": {
      right: "52px",
      top: "1px",
      "&.gx-menu-list-select": { right: -2 },
    },
    width: "200px",
    "&.gx-menu-list-auto-width": {
      width: "auto",
      minWidth: "112px",
    },
    zIndex: 1300,
    "& ul": {
      margin: 0,
      padding: 0,
      "& li": {
        cursor: "pointer",
        listStyleType: "none",
        "& a": {
          color: colorPalette.neutrals.carbon.hex,
          display: "block",
          paddingBottom: 6,
          paddingLeft: 16,
          paddingTop: 7,
          whiteSpace: "pre",
          "&:hover": {
            textDecoration: "none",
          },
        },
        "&:hover": {
          backgroundColor: colorPalette.interactions.lightBerry.hex,
        },
      },
      "& li:first-child": {
        "&:hover": {
          borderTopLeftRadius: "2px",
          borderTopRightRadius: "2px",
        },
      },
      "& li:last-child": {
        "&:hover": {
          borderBottomLeftRadius: "2px",
          borderBottomRightRadius: "2px",
        },
      },
    },
  },
  shallowOptionsList: {
    zIndex: 1050,
  },
};

const rootPopoverStyles = {
  menuRoot: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    height: "51px",
    position: "relative",
    "&.text-box-rounded": {
      borderRadius: "4px",
    },
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      textDecoration: "none",
    },
    "& p": {
      float: "left",
    },
    "& svg": {
      float: "left",
    },
    "&.open": {
      "& $helperText": {
        opacity: 1,
        visibility: "visible",
      },
    },
    "&.disabled": {
      cursor: "default",
    },
    "&.select": {
      border: `1px solid ${colorPalette.neutrals.stone.hex}`,
      padding: 12,
      width: "fit-content",
      "&.disabled": {
        border: `1px dashed ${colorPalette.neutrals.iron.hex}`,
      },
      "&.open": {
        border: `2px solid ${colorPalette.interactions.blueBerry.hex}`,
      },
    },
    minWidth: "112px",
    "&.no-show-selected-option": {
      paddingRight: 0,
      minWidth: "auto",
    },
  },
  hiddenMenuItem: {
    minWidth: "112px",
    position: "absolute",
    whiteSpace: "nowrap",
    display: "block",
    visibility: "hidden",
    flexWrap: "nowrap",
    paddingLeft: 16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.25,
    fontVariationSettings: "'wght' 500",
    paddingRight: 36,
  },
  helperText: {
    backgroundColor: basicWhiteHex,
    color: blueBerryHex,
    fontSize: "12px",
    fontVariationSettings: weightRegularVariation,
    fontWeight: weightRegularAmount,
    left: 12,
    letterSpacing: "0.15px",
    lineHeight: "12px",
    opacity: 0,
    padding: 4,
    position: "absolute",
    top: -12,
    transition: "visibility 0s, opacity 0.5s linear",
    visibility: "hidden",
  },
  titleText: {
    paddingRight: "8px",
  },
  helperTextGCOM3695: {
    color: defaultCta,
  },
  menuRootGCOM3695: {
    "&.select": {
      "&.open": {
        border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
      },
    },
  },
};

const popoverContentStyles = {
  tooltipWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropDownIcon: {
    marginLeft: "auto",
    "& .arrow-up": {
      "& svg": {
        transform: "rotate(180deg)",
      },
    },
  },
};

const selectedOptionStyles = {
  currentIcon: {
    alignItems: "center",
    display: "inline-block",
    height: "20px",
    marginRight: "10px",
    width: "20px",
  },
  currentMenuItem: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    height: "51px",
    width: "72px",
    paddingRight: "8px",
    "& p": {
      float: "left",
    },
    "& svg": {
      float: "left",
    },
    "&:hover": {
      textDecoration: "none",
    },
    "&.disabled": {
      cursor: "default",
    },
    "& .no-current-selection": {
      color: colorPalette.neutrals.iron.hex,
    },

    "&.dropdown": {
      width: "auto",
      "&.disabled": {
        "& .p3": {
          color: colorPalette.neutrals.carbon.hex,
        },
      },
      "& .p3": {
        display: "flex",
      },
      "&.dark-pear .p3": {
        color: colorPalette.status.darkPear.hex,
      },
      "&.iron .p3": {
        color: colorPalette.neutrals.iron.hex,
      },
    },
  },
  currentSelection: {
    color: colorPalette.neutrals.carbon.hex,

    fontSize: "14px",
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    letterSpacing: "0.25px",
    lineHeight: "150%",
    "&.iron": {
      color: colorPalette.neutrals.iron.hex,
    },
    "&.dark-pear": {
      color: colorPalette.status.darkPear.hex,
    },
    "&.disabled": {
      color: colorPalette.neutrals.stone.hex,
    },
    paddingLeft: "12px",
    whiteSpace: "nowrap",
  },
};

export { menuItemStyles, optionListStyles, popoverContentStyles, rootPopoverStyles, selectedOptionStyles };
