import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Drawer, makeStyles } from "@material-ui/core";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { slideInOverlayStyles as styles } from "./styles";
import { colorPalette } from "gx-npm-common-styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";

const propTypes = {
  children: PropTypes.any,
  darth: PropTypes.bool,
  headerLogo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isOpen: PropTypes.bool,
  isOverlayNavBar: PropTypes.bool,
  onClose: PropTypes.func,
  rootClassName: PropTypes.string,
  textBackBtn: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customHeader: PropTypes.any,
  textTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);

/**
 *
 * @param {{
 *   children?: any,
 *   darth?: boolean,
 *   headerLogo?,
 *   isOpen?: boolean,
 *   isOverlayNavBar?: boolean,
 *   onClose?: Function,
 *   rootClassName?: string,
 *   textBackBtn?: string,
 *   textTitle?: string,
 *   customHeader?: ReactNode,
 * }} props
 * @var props.children children components and elements
 * @var props.darth flag to determine if darth theme is applied
 * @var props.headerLogo logo to appear in header
 * @var props.isOpen flag to determine if drawer is open
 * @var props.isOverlayNavBar flag to determine if the overlay has a nav bar.
 * @var props.onClose handler for when drawer closes
 * @var props.rootClassName className set to the root of the overlay
 * @var props.textBackBtn text to get next to "BACK TO" in header
 * @var props.textTitle title text to appear in header
 * @var props.customHeader title text to appear in header in place of BACK TO
 * @var props.customHeaderText title text to appear in header
 */
const SlideInOverlay = ({
  children = null,
  darth = false,
  headerLogo = null,
  isOpen = false,
  isOverlayNavBar = false,
  onClose = null,
  rootClassName = "",
  textBackBtn = "",
  textTitle = "",
  customHeader,
}) => {
  const classes = useStyles();
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  return (
    <Drawer
      anchor={"right"}
      classes={{ root: classes.root, paper: classes.paper }}
      className={classNames(rootClassName)}
      onClose={() => handleEvent(onClose)}
      open={isOpen}
      transitionDuration={1000}
    >
      <section
        className={classNames(
          "gx-slidein-header",
          classes.containerHeader,
          darth && classes.darth,
          isFFGCOM3695 && classes.containerHeaderGCOM3695
        )}
      >
        {customHeader || (
          <div
            className={classNames(classes.headerBtn, isFFGCOM3695 && classes.headerBtnGCOM3695, darth && classes.darth)}
            onClick={() => handleEvent(onClose)}
            onKeyDown={(e) => e.key === "Enter" && handleEvent(onClose)}
            role="button"
            tabIndex={0}
          >
            <ArrowBackIcon
              fillPath={
                darth
                  ? colorPalette.basic.white.hex
                  : isFFGCOM3695
                    ? colorPalette.interactions.defaultCta.hex
                    : colorPalette.interactions.blueBerry.hex
              }
            />
            <span className={classNames("bold", classes.btnText, classes.btnStartText)}>
              {textBackBtn ? `BACK TO` : `BACK`}
            </span>
            <span className={classNames("bold", classes.btnText)}>{textBackBtn}</span>
          </div>
        )}
        {textTitle && <p className={classNames("p1", "semi-bold", classes.headerTitle)}>{textTitle}</p>}
        <div className={classes.headerLogo}>{darth ? headerLogo : <BuySmartLogoOrangeStacked />}</div>
      </section>
      <section
        className={classNames(
          "gx-slidein-body",
          darth && classes.darth,
          classes.containerBody,
          isFFGCOM3695 && classes.containerBodyGCOM3695,
          isOverlayNavBar && classes.statusOverlayNav
        )}
      >
        {children}
      </section>
    </Drawer>
  );
};

SlideInOverlay.propTypes = propTypes;
export { SlideInOverlay };
